.title-providers{
  color:#63625E;
  font-size: 25px;
  text-align: center;
  font-weight: 700;
  letter-spacing: -1px;
}
.providers {
  display: flex;
  align-items: center;
  justify-content: center;
  img{
    width: 100%;
  }
}

@media only screen and (max-width: 767px){
  .providers{
    margin-bottom: 20px;
  }
}