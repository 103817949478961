
.button-register {
  font-size: 16px;
  font-weight: bold;
  padding: 14px 38px;
  background-color: #FFD501;
  cursor: pointer;
  color:#300F94;
  border: 0;
  text-transform: uppercase;
  &:not([href]){
    color:#300F94;
    &:hover{
      color:#300F94;
      background-color: #FFD501;
    }
  }
}
