$color1: #ff1772;
$color2: #7400d2;

$g: (
        sunset2: (
                from: #241490,
                to: #ff4437
        ),
);

@function gradient($gradient, $key) {
  @return map-get(map-get($g, $gradient), $key);
}



.modal-register{
  padding-right: 0 !important;

  .modal-header{
    border-bottom: none;
    margin-bottom: 10px;
  }

  .user-icon{
    position: absolute;
    top:-80px;
    width: 130px;
    $width: 150px;
    left: calc(50% - 65px);
  }
  .form-control:focus{
    box-shadow: none;
    border-color: #ced4da;
  }
  .pl-08{
    padding-left: 0.8rem;
  }

  .modal-content{
    $border: 5px;
    border-radius: 10px;

    //&:before {
    //  content: '';
    //  position: absolute;
    //  top: 0; right: 0; bottom: 0; left: 0;
    //  z-index: -1;
    //  margin: -$border; /* !importanté */
    //  border-radius: inherit; /* !importanté */
    //  background: linear-gradient(
    //                  25deg,
    //                  gradient(#{sunset2}, from),
    //                  gradient(#{sunset2}, to)
    //  );
    //}
  }

  .modal-body {
    width: 80%;
    margin-left: auto;
    margin-right: auto;

    h4 {
      text-align: center;
      color: #333;
      text-transform: uppercase;
      font-weight: 700;
      font-size: 1.4rem;
    }
    .form-control{
      border-radius: 12px;
      font-size: 12px;
    }
    .form-check-label{
      font-size: 10px;
      color: #6c757d;
    }
    .form-check-input {
      width: 11px;
      position: relative;
      top: 3px;
      left: -4px;
    }
    .rt-1{
      position: relative;
      top: -13px;
      margin-bottom: 0;
    }
    .register-terms{
      width: 90%;
      margin-right: auto;
      margin-left: auto;
      position: relative;
      top: -10px;
      label {
        a{
          text-transform: uppercase;
          text-decoration: underline;
          color:$color2
        }
      }
      //@media screen and (max-width: 600px) {
      //  label.form-check-label {
      //    display: inline-flex;
      //  }
      //}
    }
    .btnReg{
      width: 190px;
      border-radius: 12px;
      font-size: 17px;
      border:none;
      background: linear-gradient(
                      25deg,
                      gradient(#{sunset2}, from),
                      gradient(#{sunset2}, to)
      );
    }
    .f-10{
      font-size: 10px;
      a{
        text-transform: uppercase;
        text-decoration: underline;
        color:$color2;
        font-weight: 700;
      }
    }
    .btn-twit{
      margin-top: 6px;
      display: inline-flex;
      font-size: 25px;
      border-radius: 12px;
      background-color:#1da1f2;
      border:none;
      width: 190px;
      justify-content: center;
    }
    .register-remember{
      display: flex;
      justify-content: space-between;
      margin-top: 30px;
      .form-check{
        //top: -3px;
      }
      .form-check-label{
        display: inline;
      }
      .form-check-input{
        left: 0;
        margin-right: 4px;
      }
    }
  }

  .password-icon{
    position: relative;
    top: 43px;
    float: right;
    right: 5px;
    cursor: pointer;
  }
  .mt-24{
    margin-top: -24px;
  }

  .is-invalid.reg-inv{
    background: none;
  }

  #news {
    top: -20px;
  }
}
@media only screen and (max-width: 768px) {
  .modal-register .user-icon {
    top: -60px;
    width: 110px;
    left: calc(50% - 55px);
  }

  .modal-register{
    #news {
      top: -35px;
    }
  }
}
.modal-header {
  padding: 8px;
  border: 0;
  padding-bottom: 0;
  margin-bottom: 0 !important;
  .close{
    font-size: 28px;
    &:focus{
      outline: 0;
    }
  }
}
.title-modal {
  text-transform: uppercase;
  font-size: 22px;
  margin-bottom: 32px;
}
.forgot-pass {
  text-decoration: underline;
  color: #480EF7;
  text-transform: uppercase;
  font-size: 13px;
  line-height: 18px;
  display: inline-block;
  font-weight: 700;
}
.login-Facebook {
  color: #000000 !important;
  display: inline-block;
  font-size: 13px;
  font-weight: 700;
  &:hover{
    text-decoration: none;
  }
}
.login-Facebook span.text-Facebook {
  position: relative;
  top: -10px;
  margin-right: 3px;
}
.login-Facebook span.i-Facebook {
  color: #03A9F4;
  font-size: 55px;
}
.row-out {
  position: absolute;
  right: -51%;
  top: 51.2%;
  display: inline-block;
}
.account-out {
  font-size: 29px;
  color: #fff;
  font-weight: 900;
  text-transform: uppercase;
  width: 145px;
  line-height: 31px;
}
.link-join {
  background-color: #FFD501;
  color: #000;
  font-size: 13px;
  font-weight: 900;
  text-transform: uppercase;
  padding: 13px 19px;
  margin-top: 5px;
  display: inline-block;
  cursor: pointer;
}
@media only screen and (max-width: 767px) {
  .row-out{
    display: none;
  }
  .form-check-modal.mt-m label.form-check-label{
    top: 9px;
  }
}
