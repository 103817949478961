.homepage__container,.game-container {
  max-width: 94%;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
.layout{
  max-width: 95%;
  margin: auto;
  padding: 28px 0;
}
.mb-75 {
  margin-bottom: 75px;
}
.mt-75 {
  margin-top: 75px;
}
.container-game-info,
.container-carousel {
  margin-right: auto;
  margin-left: auto;
  //max-width: 1430px;
  max-width: 73.5%;
  .row:first-of-type{
    max-width: 1400px;
    margin-left: auto;
    margin-right: auto;
  }

}
.iframe-game.toggle-full.active{
  position: inherit;

  iframe{
    height: calc(100vh);
    width: 100%;
    box-sizing: border-box;
    z-index: 2;
    position: absolute;
  }
  .close-game{
    z-index: 3;
    right: 25px;
    top: 25px;
  }
  .expand-game{
    z-index: 3;
    top: 63px;
    bottom: auto;
    right: 25px;
  }
}
.container-layout {
  max-width: 73.5%;
  //max-width: 1400px;
}
.game__m__container{
  background-image: url(../imgs/game-bg.png), linear-gradient(to bottom, #100436, #100436);
  background-position: top;
  background-repeat: no-repeat;
  margin-top: -100px;
}

.gameproducers__container {
  margin-top: 5%;
  margin-bottom: 0;
  &--title {
    padding: 1rem 0;
    font-weight: 700;
    font-size: 25px;
  }
}

.gameplay__container {
  //background: url('../imgs/game_background.jpg');
  background-position: center;
  background-size: cover;
  height: auto;
  padding-top: 140px;
  position: relative;
  .iframe-game{
    //overflow: hidden;
    padding-top: 56.25%;
    position: relative;
  }
  iframe {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    border: 25px solid #300F94;
  }
  .container{
    max-width: 75%;
    //max-width: 1430px;
    padding-left: 0;
    padding-right: 0;
  }
}

.slideshow__container {
  width: 100%;
  position: relative;
  background: url('../imgs/home-image.png');
  height: 55vh;
  background-size: cover;
  background-position: center;
  text-align: center;
  background-repeat: no-repeat;
  &--button {
    text-align: center;
    font-weight: bold;
    background-color: yellow;
    color: black;
    padding: 10px 30px;
    text-decoration: none;
    font-size: 20px;
    border-radius: 30px;
    &:hover{
      text-decoration: none;
    }
  }

  &:hover{
    cursor: pointer;
  }
  .button-register,
  .button-login{
    //position: absolute;
    bottom: 3%;
    //transform: translateX(-50%);
    font-size: 25px;
    padding: 20px 60px;
    display: inline-block;
    margin-bottom: 30px;
    margin-top: 20px;
    border-radius: 0;
  }
}
.chest__container{
  display: flex;
}

.chest-image {
  width: 100%;
}
.home-text h1{
  font-weight: 700;
  font-size: 25px;
  text-align: center;
  margin-bottom: 25px;
}
.home-text p{
  font-size: 15px;
}
.text-banner {
  position: absolute;
  background-color: #480EF7;
  width: 22vw;
  text-align: center;
  right: 0;
  left: 0;
  margin: auto;
  top: 13%;
  h1 {
    color: #fff;
    text-transform: uppercase;
    font-weight: 900;
    text-align: center;
    letter-spacing: -6px;
    line-height: 80px;
    font-size: 4.6vw;
    padding-top: 15%;
  }
  .button-register{
    font-weight: 900;
  }
}
.terms-and-conditions {
  background-color: #fff;
  .bg-img{
    background: url('../imgs/home-image.png');
    background-position: 50%;
    background-size: cover;
    height: auto;
    padding-top: 50px;
    padding-bottom: 50px;
    position: relative;
  }
  h1{
    color:#fff;
  }
}
.mask-text {
  -webkit-mask-image: linear-gradient(to top, transparent, transparent 0, #000 86%);
  mask-image: linear-gradient(to top, transparent, transparent 0, #000 86%);
}
.hidden{
  display: none;
}
.faq-content{
  display: none;
  border-top: 0;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  padding: 10px;
  box-shadow: 0 2px 15px #888888;
}
.faq-content.faq-open{
  display: block;
}

.faq-header {
  cursor: pointer;
  padding: 4px 12px;
  box-shadow: 0 0 15px #888888;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 10px;

  span{
    display: flex;
    align-items: center;
    span{
      margin-right: 10px;
      font-size: 28px;
      font-weight: 700;
    }
  }
}

.faq-header.faq-open{
  svg:first-of-type{
    display: none;
  }
  border-radius: 10px 10px 0 0;
  box-shadow: 0px -2px 10px #888888;
  color: #fff;
  background-image: linear-gradient(to right, #480EF7, #FFCC00);
}

.faq-header:not(.faq-open) {
  svg:last-of-type {
    display: none;
  }
}

.faq-section{
  width: 80%;
  margin: auto;
  font-size: 15px;
  h5{
    text-align: center;
    font-size: 22px;
    font-weight: 700;
    margin-bottom: 20px;
    margin-top: 40px;
  }
  .faq{
    margin-bottom: 30px;
  }
}

@media only screen and (max-width: 1150px) {
  .gameplay__container{
    .container {
      max-width: 85%;
    }
  }
}
@media only screen and (max-width: 991px) {
  .gameplay__container .container {
    max-width: 100%;
  }
  .close-game{
    z-index: 3;
    right: 25px;
    top: 25px;
  }
  .expand-game{
    z-index: 3;
    top: 63px;
    bottom: auto;
    right: 25px;
  }
}
@media only screen and (max-width: 768px) {
  .slideshow__container {
    //height: 120px;
    &--button {
      padding: 5px 20px;
      font-size: 15px;
      border-radius: 10px;
    }
    .button-register{
      bottom: 1%;
      font-size: 14px;
      padding: 3px 15px;
    }
  }
  .gameproducers__container{
    &--title{
      font-size: 18px;
    }
  }
  .gameplay__container{
    iframe{
      width: 370px;
      height: 210px;
    }

    h1{
      font-size: 20px;
    }

    .game-description{
      font-size: 15px;
    }
  }
  .gameproducers__container {
    margin:1rem;
  }
  .iframe-game {
    max-height:calc(100vh - 120px);
  }
  .iframe-game iframe {
    height:calc(100vh - 4px);
    width:calc(100vw - 4px);
    box-sizing: border-box;
  }
  .game__m__container .gameplay__container{
    width: 100%;
    left: 0;
    right: 0;
    padding-top: 0;
    top: 100px;
  }
  .gameplay__container iframe{
    width: 100%;
    height: 100%;
  }
  .gameplay__container.toggle-full.active{
    top: 0;
  }
  .container-carousel{
    margin-top: 100px;
  }
}
@media only screen and (min-width: 768px) {
  .slideshow__container {
    //height: 65vh;
  }

}

@media screen and (min-width: 1900px) {
  .slideshow__container {
    //min-height: 500px;
  }
}

@media only screen and (min-width: 1024px) {

  .gameproducers__container {
    margin: 5% auto;
    max-width: 1280px;
    margin-bottom: 0;
  }

  .gameplay__container iframe {
    width: 100%;
    height: 90%;
  }
  .home-text-container .home-text{
    max-width: 640px;
    margin-left: auto;
    margin-top: 15px;
  }
}
.home-image{
  margin-top: auto;
}

@media screen and (max-width: 1600px) {
  //.gameplay__container iframe {
  //  width: 90%;
  //  height: 90%;
  //}
  .gameplay__container .iframe-game{
    //margin-bottom: -11%;
  }
}

@media only screen
and (min-device-width: 375px)
and (max-device-width: 667px)
and (-webkit-min-device-pixel-ratio: 2)
and (orientation: landscape)
{
  .slideshow__container {
    //height: 50vh;

    .button-register{
      bottom: 3%;
      font-size: 14px;
      padding: 7px 15px;
    }
  }
}

/* Portrait */
@media only screen
and (min-device-width: 375px)
and (max-device-width: 667px)
and (-webkit-min-device-pixel-ratio: 2)
and (orientation: portrait) {
  .slideshow__container {
    //height: 17vh;

    .button-register{
      bottom: 1%;
    }
  }
}

/* Portrait  6+, 7+ and 8+*/
@media only screen
and (min-device-width: 414px)
and (max-device-width: 736px)
and (-webkit-min-device-pixel-ratio: 3)
and (orientation: portrait) {
  .slideshow__container {

    .button-register{
      bottom: 3%;
      font-size: 14px;
      padding: 7px 15px;
    }
  }
}

/* Landscape 6+, 7+ and 8+*/
@media only screen
and (min-device-width: 414px)
and (max-device-width: 736px)
and (-webkit-min-device-pixel-ratio: 3)
and (orientation: landscape) {
  .slideshow__container {
    //height: 45vh;

    .button-register{
      bottom: 3%;
      font-size: 14px;
      padding: 7px 15px;
    }
  }
}


/* ----------- iPhone X ----------- */
/* Portrait */
@media only screen
and (min-device-width: 375px)
and (max-device-width: 812px)
and (-webkit-min-device-pixel-ratio: 3)
and (orientation: portrait) {
  .slideshow__container {
    //height: 20vh;

    .button-register{
      bottom: 3%;
      font-size: 14px;
      padding: 7px 15px;
    }
  }
}

/* Landscape */
@media only screen
and (min-device-width: 375px)
and (max-device-width: 812px)
and (-webkit-min-device-pixel-ratio: 3)
and (orientation: landscape) {
  .slideshow__container {
    //height: 60vh;

    .button-register{
      bottom: 3%;
      font-size: 14px;
      padding: 7px 15px;
    }
  }
}

/* ----------- iPad Pro 12.9" ----------- */

/* Portrait and Landscape */
@media only screen
and (min-device-width: 1024px)
and (max-device-width: 1366px)
and (-webkit-min-device-pixel-ratio: 2) {
  //.slideshow__container {
  //  height: 33vh;
  //}
}
@media only screen and(max-width: 250px){
  .slideshow__container {
    height: 120px;
  }
  .text-banner{
    width: 24vw;
    h1{
      line-height: 15px;
      padding-top: 3%;
      font-size: 4.3vw;
      letter-spacing: -1px;
      margin-bottom: 0;
    }
    .button-register{
      padding: 1px 2px;
      font-size: 8px;
      margin-top:0;
      margin-bottom: 10px;
    }
  }
}
@media screen and (min-width:250px) and ( max-width: 350px){
  .slideshow__container {
    height: 180px;
  }
  .text-banner{
    width: 24vw;
    h1{
      line-height: 20px;
      padding-top: 3%;
      font-size: 4.3vw;
      letter-spacing: -1px;
    }
    .button-register{
      padding: 3px 8px;
      font-size: 10px;
      margin-top:0;
      margin-bottom: 15px;
    }
  }
}
@media screen and (min-width:350px) and ( max-width: 450px){
  .slideshow__container {
    height: 220px;
  }
  .text-banner{
    width: 26vw;
    h1{
      line-height: 30px;
      padding-top: 3%;
      font-size: 4.3vw;
      letter-spacing: -1px;
    }
    .button-register{
      padding: 6px 12px;
      font-size: 10px;
      margin-top:0;
      margin-bottom: 15px;
    }
  }
}
@media screen and (min-width:450px) and ( max-width: 550px){
  .slideshow__container {
    height: 270px;
  }
  .text-banner{
    width: 26vw;
    h1{
      line-height: 30px;
      padding-top: 3%;
      font-size: 4.3vw;
      letter-spacing: -1px;
    }
    .button-register{
      padding: 6px 14px;
      font-size: 14px;
      margin-top:10px;
      margin-bottom: 15px;
    }
  }
}
@media screen and (min-width:550px) and ( max-width: 600px){
  .slideshow__container {
    height: 340px;
  }
  .text-banner{
    width: 26vw;
    h1{
      line-height: 34px;
      padding-top: 3%;
      font-size: 4.3vw;
      letter-spacing: -1px;
    }
    .button-register{
      padding: 8px 18px;
      font-size: 17px;
      margin-top:10px;
    }
  }
}
@media screen and (min-width:600px) and ( max-width: 700px){
  .slideshow__container {
    height: 340px;
  }
  .text-banner{
    width: 26vw;
    h1{
      line-height: 38px;
      padding-top: 3%;
      font-size: 4.3vw;
      letter-spacing: -1px;
    }
    .button-register{
      padding: 10px 28px;
      font-size: 18px;
      margin-top:10px;
    }
  }
}
@media screen and (min-width:700px) and ( max-width: 900px){
  .slideshow__container {
    height: 505px;
  }
  .text-banner{
    width: 26vw;
    h1{
      line-height: 48px;
      padding-top: 7%;
      font-size: 4.3vw;
      letter-spacing: -1px;
    }
    .button-register{
      padding: 13px 32px;
      font-size: 20px;
    }
  }
}
@media screen and (min-width:900px) and ( max-width: 991px){
  .chest-image {
    width: 135%;
  }
  .home-image{
    overflow: hidden;
  }
  .text-banner{
    width: 26vw;
    h1{
      line-height: 50px;
      padding-top: 7%;
      font-size: 4.3vw;
      letter-spacing: -1px;
    }
    .button-register{
      padding: 13px 32px;
      font-size: 20px;
    }
  }
}
@media screen and (min-width:991px) and ( max-width: 1200px){
  .text-banner{
    h1{
      line-height: 50px;
      padding-top: 7%;
      font-size: 4.3vw;
      letter-spacing: -4px;
    }
    .button-register{
      padding: 15px 35px;
      font-size: 22px;
    }
  }
}
@media screen and (min-width:1200px) and ( max-width: 1600px){
  .slideshow__container {
    height: 580px;
  }
  .text-banner{
    h1{
      line-height: 66px;
    }
    .button-register{
      font-size: 20px;
      padding: 15px 41px;
    }
  }

}
@media screen and (min-width:1600px) and ( max-width: 1900px){
  .slideshow__container {
    height: 720px;
  }
}

@media screen and (min-width:1900px) and ( max-width: 2400px){
  .slideshow__container {
    height: 720px;
  }
  .text-banner{
    h1{
      line-height: 90px;
      padding-top: 7%;
      font-size: 4.3vw;
      letter-spacing: -4px;
    }
    .button-register{
      padding: 15px 40px;
      font-size: 23px;
    }
  }
}

@media screen and (min-width:2400px) and ( max-width: 2900px) {
  .slideshow__container {
    height: 750px;
  }
  .text-banner{
    h1{
      line-height: 90px;
      padding-top: 7%;
      font-size: 4.3vw;
      letter-spacing: -4px;
    }
    .button-register{
      padding: 15px 40px;
      font-size: 23px;
    }
  }
}

@media screen and (min-width:2900px) and ( max-width: 3500px){
  .slideshow__container {
    height: 900px;
  }
  .text-banner{
    h1{
      line-height: 110px;
      padding-top: 7%;
      font-size: 4.3vw;
      letter-spacing: -4px;
    }
    .button-register{
      padding: 15px 40px;
      font-size: 23px;
    }
  }
}

@media screen and (min-width:3500px){
  .slideshow__container {
    height: 1000px;
  }
  .text-banner{
    h1{
      line-height: 110px;
      padding-top: 7%;
      font-size: 4.3vw;
      letter-spacing: -4px;
    }
    .button-register{
      padding: 15px 40px;
      font-size: 23px;
    }
  }
}
