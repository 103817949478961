//@import url('https://fonts.googleapis.com/css?family=Montserrat:400,700');
@import url('https://fonts.googleapis.com/css?family=Noto+Sans+JP&display=swap');

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
}

body {
  font-family: Arial, Helvetica, sans-serif;
  background-color: #300F94;
}
h2 {
  font-weight: 800;
  letter-spacing: -1px;
  font-size: 1.7rem;
}

h3 {
  color: #fff;
  font-size: 21px;
  font-weight: 700;
  letter-spacing: -1px;
}

input, textarea {
  -webkit-user-select: text;
  -khtml-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
}

.term-paragraph {
  font-size: 14px;
}

.plt-capacitor.plt-ios {
  margin-top: 40px;
}

.plt-capacitor.plt-ios .mobile{
  display: none;
}

.plt-capacitor.plt-android .mobile{
  display: none;
}


*{
  scroll-behavior: smooth !important;
}
