.bg-yellow{
  background-color: #F8CE06
}
.primary-color{
  color:#300F94;
}
.color-white{
  color:#fff;
}
.color-black1 {
  color: #302F2F;
}