.button-login {
  font-size: 16px;
  font-weight: bold;
  padding: 14px 38px;
  border:1px solid rgba(255,255,255, 0.47);
  background-color: transparent;
  color:#fff;
  cursor: pointer;
  text-transform: uppercase;
  &:not([href]){
    color:#fff;
    &:hover{
      color:#fff;
    }
  }
}
.game-img-hover{
  .button-login {
    background-color: #300F94;
  }
}

@media only screen and (min-width: 991px) and (max-width: 1260px) {
  .homepage__container .button-login,
  .div-homepage-register .button-login{
    padding: 14px 24px;
  }
}

@media only screen and (min-width: 991px) and (max-width: 1150px) {
  .div-homepage-register .button-login{
    padding: 14px 20px;
  }
}

@media only screen and (min-width: 991px) and (max-width: 1020px) {
  .game-img img, .game-img-hover img {
    height: 155px;
  }
  .div-homepage-register .button-login{
    padding: 14px 18px;
    font-size: 14px;
  }
}

@media only screen  and (max-width: 700px) {
  .game-img img, .game-img-hover img {
    height: 200px;
  }
  .game-play {
    top: -35px;
  }
}

@media only screen and (min-width: 575px) and  (max-width: 650px) {
  .div-homepage-register .button-login{
    padding: 14px 18px;
    font-size: 14px;
  }
}
@media only screen and (max-width: 575px){
  .game-img img, .game-img-hover img {
    height: 240px;
  }
}
@media only screen and (max-width: 400px){
  .game-img img, .game-img-hover img {
    height: 200px;
  }
  .game-play{
    margin-left: 10px;
  }
  .game-play-hover{
    left: 20px;
    bottom: 15px;
  }
}
@media only screen and (max-width: 350px){
  .game-img img, .game-img-hover img {
    height: 180px;
  }
}