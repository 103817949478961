.footer-text {
  color: #F5F5F5;
  opacity: 0.5;
  font-weight: 700;
  margin-bottom: 0;
}
.footer-link {
  color: #fff;
  font-weight: 700;
}
.copy-text {
  font-weight: 700;
  color: #F5F5F5;
  opacity: 0.5;
  margin-top: 15px;
  font-size: 13px;
  margin-left: auto;
  margin-right: auto;
}
@media only screen and (max-width: 767px) {
  .hide-mobile{
    display: none;
  }
  .order-2-m{
    order: 2;
    margin-bottom: 20px;
  }
  .order-1-m.text-right{
    text-align: center!important;
    margin-bottom: 20px;
  }
  .order-1-m{
    order: 1;
  }
  .order-3-m{
    order: 4;
    margin-bottom: 20px;
  }

}
