section {
  width: 50em;
  margin: 2em auto;
  padding: 2em;
  background: #e9e9e9;
  text-align: center;
  position: relative;

  &:before {
    content: attr(class);
    position: absolute;
    top: 1em;
    left: 1em;
    text-transform: uppercase;
    font-weight: bold;
    color: #ccc;
  }
}

.button-login {
  text-align: center;
  letter-spacing: 0.5px;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: bolder;
  cursor: pointer;
  background-color: #480EF7;
}
.show-content-btn{
  margin-top: 20px;
  background-color: #300F94 !important;
  display: inline-block;
  &:hover{
    color: #fff;
  }
}
.owl-nav{
  position: relative;
  top: -40px;
  .owl-prev{
    font-size: 50px !important;
    position: relative;
    left: -15%;
  }
  .owl-next{
    font-size: 50px !important;
    position: relative;
    right: -15%;
  }
}
.owl-theme .owl-nav [class*='owl-']:focus{
  outline: none !important;
}
.owl-theme .owl-nav [class*='owl-']:hover{
  background: none !important;
  color: initial !important;
}


.forgot-pass{
  font-size: 10px;
  text-decoration: underline;
  color: #480EF7;
  font-weight: 700;
}



.owl-theme .owl-nav{
  position: absolute;
  height: 100%;
  margin-top: 0 !important;
  top: 0;
  width: 100%;

  button {
    border-radius: 0 !important;
  }
  button span{
    color:#707070;
    position: relative;
  }

  .owl-prev{
    position: absolute;
    margin: 0 !important;
    left: 0;
    height: 100%;
    width: 70px;
    background-image: linear-gradient(to left,  rgba(16,4,54,0.05) 10%, rgba(16,4,54,0.4) 20%, #100436 100%) !important;
    opacity: 1;
    &:hover{
      background-image: linear-gradient(to left,  rgba(16,4,54,0.05) 10%, rgba(16,4,54,0.4) 20%, #100436 100%) !important;
    }
    span{
      left: -20px;
    }
  }
  .owl-next{
    position: absolute;
    margin: 0 !important;
    height: 100%;
    right: 0;
    width: 70px;
    background-image: linear-gradient(to right, rgba(16,4,54,0.05) 10%, rgba(16,4,54,0.4) 30%, #100436 100%) !important;
    opacity: 1;
    &:hover{
      background-image: linear-gradient(to right, rgba(16,4,54,0.05) 10%, rgba(16,4,54,0.4) 30%, #100436 100%) !important;
    }
    span{
      right: -20px;
    }
  }
}

.owl-stage-outer{
  .owl-stage{
    padding: 0 !important;
  }
}
.close-game {
  color: #fff !important;
  z-index: 1;
  position: absolute;
  top: 0;
  background-color: #300F94;
  right: -40px;
  text-align: center;
  cursor: pointer;
  font-size: 18px;
  padding: 6px 11px;
}
.expand-game {
  color: #fff !important;
  z-index: 1;
  position: absolute;
  background-color: #300F94;
  text-align: center;
  cursor: pointer;
  font-size: 18px;
  padding: 6px 11px;
  right: 25px;
  bottom: calc(10% + 25px);
}
.login-btn {
  color: #fff;
  background-color: #480EF7;
  font-weight: 900;
  text-transform: uppercase;
  border: 0;
  font-size: 20px;
  padding: 15px 40px;
  cursor: pointer;
}
.btn-custom{
  font-size: 16px;
  font-weight: bold;
  padding: 14px 38px;
}