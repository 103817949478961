
.modal-register{
  .modal-body{
    .form-control.input-modal{
      width: 100%;
      line-height: 43px;
      margin-bottom: 22px;
      color: #707070 !important;
      font-size: 12px;
      letter-spacing: 2px;
      border-radius: 0 !important;
      height: 60px;
    }
  }
}

.form-check-modal{
  label.form-check-label{
    font-size: 13px !important;
    line-height: 13px;
    color: #535353 !important;
    margin-left: 8px;
    margin-bottom: 28px;
    position: relative;
    top: 2px;
  }
}
.modal-register .modal-body .form-check-input {
  width: 13px;
}

.check-container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 30px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 13px;
  line-height: 13px;
  color: #535353;
}

.check-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 21px;
  width: 21px;
  background-color: #eee;
}

.check-container:hover input ~ .checkmark {
  background-color: #ccc;
}

.check-container input:checked ~ .checkmark {
  background-color: #480EF7;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.check-container input:checked ~ .checkmark:after {
  display: block;
}

.check-container .checkmark:after {
  left: 8px;
  top: 4px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.check-container .text-checkbox{
  position: relative;
  top: 3px;
}
.form-check-input{
  transform: scale(1.5);
  -ms-transform: scale(1.5);
  -webkit-transform: scale(1.5);

  &:checked
  {
    background: white no-repeat;
    background-size: 20px 20px;
    background-position: 50% 50%;
  }
}