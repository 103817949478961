.game-img img{
  width: 100%;
  height: 235px;
  position: relative;
  z-index: -1;
}
.game-img{
  background-image: linear-gradient(to bottom, rgba(48, 15, 148, 0), rgba(48, 15, 148, 1));
}
.game-play h3{
  position: relative;
  top: -15px;
}
.game-play{
  position: relative;
  top: -20px;
  margin-left: 35px;
}
.game-img-hover img{
  width: 100%;
  height: 235px;
}
.game-play-hover{
  position: absolute;
  bottom: 35px;
  left: 45px;
}
.game-play-hover h3{
  margin-bottom: 30px;
}
.game-img-hover .game-play-hover{
  visibility: hidden;
}
.game-img-hover:hover .game-play-hover{
  visibility: visible;
}
.game-img-hover:hover:before {
  position: absolute;
  width: calc(100% - 30px);
  content: ' ';
  height: 100%;
  background-image: linear-gradient(to bottom, rgba(48, 15, 148, 0), rgba(48, 15, 148, 1));
}
.game-item img{
  max-height: 250px;
  width: 100%;
}
.game-title {
  font-size: 40px;
  color: #E6E6E6;
  margin-bottom: 30px;
}
.game-text {
  color: #E6E6E6;
  font-weight: 700;
  font-size: 16px;
}
.game-blur {
  opacity: 0.4;
}
@media screen and (min-width: 991px) and (max-width: 1400px) {
  .game-img img,
  .game-img-hover img{
    height: 200px;
  }
  .game-play-hover{
    left: 30px;
  }
}

@media screen and (min-width: 991px) and (max-width: 1150px) {
  .game-img img,
  .game-img-hover img{
    height: 170px;
  }
  .game-play{
    margin-left: 10px;
  }
}
@media screen and (min-width: 991px) and (max-width: 1100px) {
  .game-play-hover {
    bottom: 20px;
    left: 23px;
    h3 {
      margin-bottom: 20px;
      font-size: 18px;
    }
  }
}
@media screen and (max-width: 1030px) {
  .game-title{
    margin-top: 20px;
  }
}
@media screen and (min-width: 575px) and (max-width: 650px) {
  .game-play-hover {
    bottom: 20px;
    left: 23px;
    h3 {
      margin-bottom: 20px;
      font-size: 18px;
    }
  }
}
